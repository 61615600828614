import { AddBusiness, Business, Mail, Phone, Public, Store } from "@mui/icons-material";
import { Box, FormControl, TextField, Button } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";


export const AddCompnayForm = ({ setmodel }) => {
    const { addcmpny } = useAuth();
    const initialState = {
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postCode: '',
    };
    const [formData, setFormData] = useState(initialState);
    var { name, email, phone, address, city, state, country, postCode } = formData;
    const submitNewBuisness = async () => {
        // alert('This is for Buisness Info');
        addcmpny(formData);
        setFormData(initialState);
        setmodel(false);

    }

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <form onSubmit={submitNewBuisness}>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                    <TextField
                        placeholder="Buisness Ltd. , Corp."
                        label="Buisness Name"
                        id="BuisnessName"
                        sx={{ m: 1, width: '100%' }}
                        value={name}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Store />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        required
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                    <TextField
                        placeholder="Buisness Email"
                        label="Email"
                        id="bemail"
                        sx={{ m: 1, width: '100%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Mail />
                                </InputAdornment>
                            )
                        }}

                        value={email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                    <TextField
                        placeholder="Buisness Phone"
                        label="Phone"
                        id="bphone"
                        sx={{ m: 1, width: '100%' }}
                        value={phone}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Phone />
                                </InputAdornment>
                            )
                        }}
                        required
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '95%' }} variant="outlined">
                    <TextField
                        placeholder="Buisness Address"
                        label="Address"
                        id="address"
                        sx={{ m: 1, width: '100%' }}
                        value={address}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Business />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                        required
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '30%' }} variant="outlined">
                    <TextField
                        placeholder="Calgary, Toronto, New York"
                        label="City"
                        id="cty"
                        sx={{ m: 1, width: '100%' }}
                        value={city}
                        onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                        required
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '30%' }} variant="outlined">
                    <TextField
                        placeholder="AB / QC / ON ..."
                        label="State / Province"
                        id="pro"
                        sx={{ m: 1, width: '100%' }}
                        value={state}
                        onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                        required
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '30%' }} variant="outlined">
                    <TextField
                        placeholder="Postal Code"
                        label="Postal Code"
                        id="pcode"
                        sx={{ m: 1, width: '100%' }}
                        value={postCode}
                        onChange={(e) => setFormData({ ...formData, postCode: e.target.value })}
                        required
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '50%' }} variant="outlined">
                    <TextField
                        placeholder="Country"
                        label="Country"
                        id="cntry"
                        sx={{ m: 1, width: '100%' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Public />
                                </InputAdornment>
                            )
                        }}
                        required
                        value={country}
                        onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    />
                </FormControl>
                <Box sx={{ display: "flex", pt: 5, pl: 2 }} style={{}}>
                    <Button variant="contained" type="submit">ADD &nbsp;&nbsp; <AddBusiness /></Button>
                </Box>
            </form>
        </Box>
    )
};