import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Collapse, Grid, CardActionArea } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { renderTime } from '../hooks/commanFunc';



const OrderCollapsibleCard = ({ title, orders }) => {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    

    return (
        <Card sx={{ margin: 2, backgroundColor:'#b6ebc9'}} elevation={5}>
            <CardActionArea 
            onClick={handleExpandClick}
            aria-expanded={expanded}
            >
            <CardContent>
                <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Grid item xs={8}>
                        <Typography variant="h6" component="div">
                            Table - {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            style={{ marginLeft: 'auto' }}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
            </CardActionArea>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {orders.map((order, index) => (
                        <div key={index}>
                            <Typography variant="body2" color="text.secondary">

                                Order {index + 1} - Client ID: {order.cName} ({order.clientID}) - Time: {renderTime(order.dateTime)}
                            </Typography>
                            <ul>
                                {order.order.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                        {item.quantity} x {item.item} - ${item.price} each
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default OrderCollapsibleCard;
