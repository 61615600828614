// import ReactDOM from "react-dom";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./hooks/useAuth";
// import { createRoot } from "react-dom/client";
import * as ReactDOMClient from 'react-dom/client';

import App from "./App";
import SnackbarProvider from "./hooks/SnackbarProvider";

//  const rootElement = document.getElementById('root');
// const root = createRoot(document.getElementById('root'));


const rootElement = document.getElementById("root");

const root = ReactDOMClient.createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: { main: "#3a34d2" }
  }
});

root.render(
  // </React.StrictMode>
    <BrowserRouter>
    <SnackbarProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </AuthProvider>
      </SnackbarProvider>
    </BrowserRouter>
  // </React.StrictMode>
  );



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
