import React from "react";
import LayoutBuilder from "../../LayoutBuilder/layoutBuilder";


export const Adash = () => {
  return (
    <div>
      <h6> Admin Dashboard</h6>
      {/* <DnDFlow /> */}
      
    </div>
  );
};
