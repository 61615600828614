import React from "react";

import { Container } from "@mui/material";

export const Morders = () => {
  return (
    <Container>
<h6> Manager Oreder Pages</h6>
    </Container>
  );
};
