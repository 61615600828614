import React, { useState, useEffect } from 'react';

const Element = ({ id, name, type, x, y, width, height, lbr, canvasWidth, canvasHeight, onPositionChange, onElementChange }) => {
  const [position, setPosition] = useState({ x: x, y: y });
  const [size, setSize] = useState({ width, height });
  const [elementDetails, setElementDetails] = useState({
    name,
    width,
    height,
    lbr
  })
  const [showDialog, setShowDialog] = useState(false);
  const [rotateLabel, setRotateLabel] = useState(false); // State for label rotation
  const [ename, seteName] = useState(name);
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  // Function to handle double click event
  const handleDoubleClick = () => {
    if (type === 'counter') {
      setShowDialog(true);
    }
  };

  // Function to close dialog
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  // Function to handle mouse down event
  const handleMouseDown = (e) => {
    setIsDragging(true);
    setDragOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
  };

  // Function to handle mouse move event
  const handleMouseMove = (e) => {
    if (!isDragging) return;

    let newX = e.clientX - dragOffset.x;
    let newY = e.clientY - dragOffset.y;

    // Calculate the maximum position to prevent element from going outside canvas
    newX = Math.max(0, Math.min(newX, canvasWidth - size.width));
    newY = Math.max(0, Math.min(newY, canvasHeight - size.height));

    setPosition({ x: newX, y: newY });

    // Call the callback function to update position in parent component
    onPositionChange({ x: newX, y: newY });
  };

  // Function to handle mouse up event
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const { name, value,checked } = e.target;
    if(name === 'lbr'){
      setElementDetails(prevSize => ({
        ...prevSize,
        [name]: checked
      }));
    }else if(name === 'name'){
      setElementDetails(prevSize => ({
        ...prevSize,
        [name]: value
      }));
    }else{
      setElementDetails(prevSize => ({
        ...prevSize,
        [name]: parseInt(value) || 0
      }));
    }
  };

  // Function to handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const newWidth = Math.max(size.width, 0);
    const newHeight = Math.max(size.height, 0);
    // setSize({ width: newWidth, height: newHeight });
    // onSizeChange({ width: newWidth, height: newHeight });
    onElementChange(elementDetails);
    setShowDialog(false);
  };

  // Style based on element type
  let elementStyle = {};
  switch (type) {
    case 'counter':
      elementStyle = { backgroundColor: 'gray', border: '1px solid black', padding: '10px 20px', color: 'white' };
      break;
    default:
      break;
  }

  function pelement(rot) {
    if (rot) {
      return { padding: '1px' };
    } else {
      return { margin: 2 };
    }
  }

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;

      let newX = e.clientX - dragOffset.x;
      let newY = e.clientY - dragOffset.y;

      // Calculate the maximum position to prevent element from going outside canvas
      newX = Math.max(0, Math.min(newX, canvasWidth - size.width));
      newY = Math.max(0, Math.min(newY, canvasHeight - size.height));

      setPosition({ x: newX, y: newY });

      // Call the callback function to update position in parent component
      onPositionChange({ x: newX, y: newY });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragOffset, canvasWidth, canvasHeight, size, onPositionChange]);

  return (
    <>
      <div
        className="restaurant-table"
        style={{ left: position.x, top: position.y, width: elementDetails.width, height: elementDetails.height, ...elementStyle ,transform: (elementDetails.lbr) ? 'rotate(-90deg)' : 'none', }}
        onMouseDown={handleMouseDown}
        onDoubleClick={handleDoubleClick}
      >
        <p style={{
          verticalAlign: 'center',
          textAlign: 'center',
          textTransform: 'uppercase',
          // transform: rotateLabel ? 'rotate(-90deg)' : 'none',
          ...pelement(rotateLabel)
        }}>
          {elementDetails.name} {(type === 'table') ? id : ''}
        </p>
      </div>
      {showDialog && (
        <dialog style={{ zIndex: 110 }} open onClose={handleDialogClose}>
          <h2>Edit <span style={{ textTransform: 'uppercase' }}>{type}</span> {id} Properties</h2>
          <form onSubmit={handleFormSubmit}>
            <label>
              Element Name:
              <input type="text" name="name" value={elementDetails.name} onChange={handleInputChange} />
            </label>
            <br />
            <label>
              Width:
              <input type="number" name="width" value={elementDetails.width} onChange={handleInputChange} />
            </label>
            <label>
              Height:
              <input type="number" name="height" value={elementDetails.height} onChange={handleInputChange} />
            </label>
            <label>
              Rotate Label:
              <input type="checkbox" name="lbr" checked={elementDetails.lbr} onChange={handleInputChange} />
            </label>
            <button type="submit">Save</button>
            <button type="button" onClick={handleDialogClose}>Cancel</button>
          </form>
        </dialog>
      )}
    </>
  );
};

export default Element;
