import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  card: {
    minWidth: 150,
    margin: 5,
    backgroundColor:'#3ad2ce !important'
  },
});

const OrderCardView = ({ currentOrder, inStore, online, partners, completed }) => {
  const classes = useStyles();

  return (
    <div className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Current Order
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {currentOrder}
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            In-Store
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {inStore}
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Online
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {online}
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Partners
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {partners}
          </Typography>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Completed
          </Typography>
          <Typography variant="h4" fontWeight="bold">
            {completed}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

export default OrderCardView;
