import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material"
// import { TableContainer, Paper, TableBody, Table, TableHead, TableRow } from "@mui/material"
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Restaurant, MenuBook } from "@mui/icons-material";
import { Container } from "@mui/material";
import { menu } from "../data";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from '@mui/material/Collapse';
// import { styled } from '@mui/material/styles';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

// const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export const Mmenu = () => {

  var [listData, listHandler] = useState(menu);


  function buildList(menuData) {
    menuData.map((value, index) => {
      const updateField = [...listData];
      updateField[index].listAct = false;
      return listHandler(updateField);
    });

  }
  useEffect(() => {
    buildList(menu);
  })

  function handleDropDown(index) {
    const updateField = [...listData];

    console.log(index, updateField[index].listAct);
    updateField[index].listAct = !(updateField[index].listAct);
    console.log(updateField[index].listAct);
    listHandler(updateField);
  }


  return (
    <Container>
      {/* This Following Should be in Admin Panel */}
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="outlined">+Add Menu</Button>
      </Box>
      <Box sx={{ p: 2 }}></Box>
      {/* End of extra Admin Panel */}

      <List>
        {listData.map((item, index) => (

          <>
            <ListItemButton key={index} onClick={() => handleDropDown(index)}>
              <ListItemIcon>
                <MenuBook />
              </ListItemIcon>
              <ListItemText primary={item.menuName} autoCapitalize="true" />
              {/* {console.log(listData[index])} */}
              {listData[index].listAct ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={listData[index].listAct} timeout="auto" unmountOnExit>
              <List disablePadding>
                {item.items.map((nestedItem, index) => (
                  <>
                    <ListItemButton sx={{ pl: 4 }} key={`nest-${index}`}>
                      <ListItemIcon>
                        <Restaurant />
                      </ListItemIcon>
                      <ListItemText primary={nestedItem.itemName} secondary={nestedItem.price} />
                    </ListItemButton>

                  </>
                ))}
              </List>
            </Collapse>
          </>

        ))}
      </List>

      {/* this is in case of different view... */}
      {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="right">Item</StyledTableCell>
            <StyledTableCell align="right">Catagory</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Available</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
    </Container>
  );
};
