import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        borderRadius: theme.shape.borderRadius,
    },
    countdown: {
        display: 'inline',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    circularProgress: {
        margin: 'auto', // Center the progress bar
        width: theme.spacing(8), // Adjust size as needed
        height: theme.spacing(8),
        position: 'relative', // Position for text overlay
    },
    countdownText: {
        position: 'absolute', // Overlay the text on the progress bar
        top: '50%', // Center vertically
        left: '50%', // Center horizontally
        transform: 'translate(-50%, -50%)', // Account for positioning adjustments
        fontSize: theme.typography.h6.fontSize, // Adjust font size as needed
        fontWeight: 'bold', // Emphasize time display
        color: theme.palette.getContrastText(theme.palette.primary.main), // Use contrasting color for better visibility
    },
}));

const TokenPopup = ({ token, onTokenExpire, countdownTime = 300 }) => {
    const classes = useStyles(); // Import useStyles function below

    const [open, setOpen] = useState(true); // Control popup visibility
    const [remainingTime, setRemainingTime] = useState(countdownTime);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingTime((prevTime) => Math.max(0, prevTime - 1));
        }, 1000);
        if (remainingTime === 0) {
            handleClose();
        }
        // Cleanup function to clear the interval on unmount
        return () => {
            clearInterval(intervalId);
        };
    }, [remainingTime]);

    const handleClose = () => {
        setOpen(false);
        onTokenExpire && onTokenExpire(); // Call provided callback
    };

    const formattedTime = Math.floor(remainingTime / 60) + ':' + (remainingTime % 60).toString().padStart(2, '0');
    const progress = (remainingTime / countdownTime) * 100; // Calculate progress percentage

    return (
        <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'spaceBetween' }}>
                <Typography variant="h">Token : <b>{token}</b></Typography>
            </DialogTitle>
            <DialogContent >
                <div style={{display:'inline-flex', alignItems:'center'}}>
                    <div style={{paddingTop:5, paddingRight:10}}>Expires in :</div>
                    <div className={classes.countdown}>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            <CircularProgress variant="determinate" value={progress} />
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                >{formattedTime}</Typography>
                            </Box>
                        </Box>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClose} disabled={remainingTime === 0}>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TokenPopup;
