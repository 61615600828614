import Axios from "axios";
import { url } from "./commanFunc";

const apiInst = Axios.create({
    baseURL: url,
});

const authHeader = () => ({
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('user'))}`,
        "Cache-Control": "no-cache",
    }
});

const validationCheck = (data, Logout) => {
    //  console.log(data);
    if (data.status === 401) {
        console.log('Need to logout...');
        Logout();
    }else if(data.status === 406){
        console.log('this is to handle invalide input ans open small alert');
    }

    return data.data;
}

export const signupRestaurant = async (data) => {
    // console.log(`Sending Data To SignUP ${data}`);
    try {
        const response = await apiInst.post('/signup', { data });
        // console.log(response);
        return response.data;
    } catch (error) {
        // console.error('Signup Error:', error);
        return validationCheck(error.response);
    }
}

export const loginRest = async (data, Logout) => {
    try {
        const response = await apiInst.post('/login', data);
        return response.data;
    } catch (error) {
        console.error('Login Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const addStore = async (data, Logout) => {
    try {
        const response = await apiInst.post('/addStore', data, authHeader());
        return response.data;
    } catch (error) {
        console.error('Add Store Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const getlistMenu = async (data, Logout) => {
    try {
        const response = await apiInst.post('/listMenu', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Get List Menu Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const addMenu = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/addMenu', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Add Menu Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const sendOrder = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/sendOrder', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Send Order Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const checkOut = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/orderCheckout', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Check Out Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const itemRemove = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/removeItem', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Item Remove Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const ListStore = async (Logout) => {
    try {
        const response = await apiInst.post('/listStore', {}, authHeader());
        return response.data;
    } catch (error) {
        console.error('List Store Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const addUser = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/addUser', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Add User Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const editUser = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/editUser', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Edit User Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const listUser = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/listUser', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('List User Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const deleteUser = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/removeUser', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Delete User Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const saveLayout = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/addLayout', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Save Layout Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const getLayout = async (data, Logout) => {
    try {
        const response = await apiInst.post('/layout', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Get Layout Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const generateDeviceToken = async (data, Logout) => {
    try {
        const response = await apiInst.post('/device/generateToken', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Generate Device Token Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const getdeviceInfo = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/devices', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Get Device Info Error:', error);
        return validationCheck(error.response, Logout);
    }
}
export const getCurrentOrder = async(data, Logout) => {
    try {
        const response = await apiInst.post('/currentOrder', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Get currentOrder Info Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const removeDevice = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/removeDevice', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Remove Device Error:', error);
        return validationCheck(error.response, Logout);
    }
}


//****API handling for general section in setting page */
export const getStoreInfo = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/storeInfo', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Get StoreData loading Error:', error);
        return validationCheck(error.response, Logout);
    }
}

export const editStoreInfo = async (data, Logout) => {
    try {
        const response = await apiInst.post('/admin/storeInfoEdit', data, authHeader());
        return validationCheck(response, Logout);
    } catch (error) {
        console.error('Get StoreData loading Error:', error);
        return validationCheck(error.response, Logout);
    }
}