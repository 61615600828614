
import React from "react";
import { Card, Container, Grid, Switch } from "@mui/material";

export const PaymentSetting = () => {
    return (
        <Container>
            <h4>Payment Options :</h4>
            <hr />
            <Grid container>
                <Grid item xs={6} md={2} xl={3}>
                    <Card>
                        this show image
                    </Card>
                </Grid>
            </Grid>
            <br/>
            <h4>Other Options :</h4>
            <hr />
            <Grid container>
                <Grid item xs={11} md={8}>
                    Tip Option
                </Grid>
                <Grid item xs={1} md={1}>
                    <Switch
                        checked
                    />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={11} md={8}>
                    Show order details to terminal
                </Grid>
                <Grid item xs={1} md={1}>
                    <Switch
                        checked
                    />
                </Grid>
            </Grid>
        </Container>
    )
}