// src/middleware/apiMiddleware.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { url } from './commanFunc';

const ApiPostH = (apiUrl, inputParm) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  


  useEffect(() => {
    const fetchData = async () => {
      const authHeader = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('user'))}`,
          withCredentials: true,
          crossdomain: true,
          "Cache-Control": "no-cache",
        }
      }
      try {
        const response = await axios.post(url + apiUrl, inputParm, authHeader);
        console.log(response);
        setData(response.data);
      } catch (error) {
        console.error('API request failed:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, [apiUrl,inputParm]);

  return { data, loading };
};

export default ApiPostH;
