import { AdminPanelSettings, AlternateEmail, Delete, Edit,  Person2, Phone, Shield, SupervisorAccount, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import { ModalUnstyled } from "../../modal/Modal";
import { randomNumber } from "../../hooks/commanFunc";
import { addUser, deleteUser, editUser, listUser } from "../../hooks/apiHandler";


export const UserSetting = () => {
    const [autogenerate, setautoGenerate] = useState(false);
    const [listStoreuser, setlistStoreUser] = useState([]);
    const [userModal, setUserModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [dialogInput, setDialogInput] = useState({
        dialog: false,
        userid: 0
    });
    const [userData, setUserData] = useState({
        userID: null,
        storeID: JSON.parse(window.localStorage.getItem('ds')),
        name: '',
        email: '',
        password: '',
        phone: '',
        userType: 2
    });
    const { name, email, password, phone, userType, storeID } = userData;
    

    useEffect(() => {
        // Call fetchData only once when the component mounts
        async function fetchData() {
            var response = await listUser({ storeID: storeID });
            setlistStoreUser(response.data);
        }
        fetchData();
    }, [storeID]);

    const handleCloseModel = () => {
        setUserModal((prev) => !prev);
        setUserData({
            userID: null,
            storeID: storeID,
            name: '',
            email: '',
            password: '',
            phone: '',
            userType: 2
        });
    };
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    return (
        <Container key={'sul'} style={{ alignSelf: 'flex-start' }}>
            <Box textAlign={"end"}>
                <Button
                    variant="contained"
                    sx={{ margin: "3px" }}
                    onClick={() => setUserModal(true)}
                >+ Add User</Button>
            </Box>
            <Box padding={1} />
            {
                listStoreuser.length > 0 ? (
                    listStoreuser.map((user) => (
                        <Box key={user.id} margin={1}>
                            <Card elevation={4}>
                                <CardContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} md={8}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {user.name}
                                                &nbsp;
                                                <Typography variant="body2" component="span" color="textSecondary">
                                                    ({user.userID})
                                                </Typography>
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {user.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} md={2} textAlign="center" margin={'auto 0 auto 0'}>
                                            {
                                                (user.type === 0) ? <Chip size="small" label="Admin" style={{ borderColor: "#ff6d00", color: "#ff6d00" }} variant="outlined" />
                                                    : (user.type === 1) ? <Chip size="small" label="Manager" style={{ borderColor: "#ffab00", color: "#ffab00" }} variant="outlined" /> :
                                                        (user.type === 2) ? <Chip size="small" label="User" style={{ borderColor: "#3f51b5", color: "#3f51b5" }} variant="outlined" /> : ''
                                            }
                                        </Grid>
                                        <Grid item xs={3} md={2} textAlign={"end"} margin={'auto 0 auto 0'}>
                                            <IconButton
                                                onClick={() => {
                                                    console.log('Time to edit ', user.userid);
                                                    /// set data to dialog...
                                                    setUserData({
                                                        userID: user.userID,
                                                        storeID: storeID,
                                                        email: user.email,
                                                        name: user.name,
                                                        password: '',
                                                        phone: user.phone,
                                                        userType: user.type
                                                    });
                                                    setUserModal(true);
                                                    // open dialog
                                                }}
                                            ><Edit /></IconButton>
                                            <IconButton onClick={() => {
                                                setDialogInput({ dialog: true, userid: user.userID });
                                            }}><Delete /></IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                    ))
                ) : 'Loading....'
            }


            <ModalUnstyled shouldShow={userModal} onRequestClose={handleCloseModel} >
                <h2>{(userData.userID) ? 'Edit' : 'Create New'} User</h2>
                <hr />
                <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                    <InputLabel htmlFor="User Name">Name</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type='text'
                        value={name}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                        startAdornment={
                            <InputAdornment position="start" disablePointerEvents>
                                <Person2 />
                            </InputAdornment>
                        }
                        label="Name"
                        required
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '45ch' }} variant="outlined">
                    <InputLabel htmlFor="User Email">Email</InputLabel>
                    <OutlinedInput
                        id="user-email"
                        type='email'
                        value={email}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                        startAdornment={
                            <InputAdornment position="start" disablePointerEvents>
                                <AlternateEmail />
                            </InputAdornment>
                        }
                        label="Email"
                        required
                    />
                </FormControl>
                <Grid container spacing={1}>
                    <Grid item sx={6}>
                        <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="user-password"
                                value={password}
                                onChange={(e) => {
                                    if (autogenerate) {
                                        setautoGenerate(false);
                                    }
                                    setUserData({ ...userData, password: e.target.value });
                                }}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="User Password"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item sx={6} style={{ margin: 'auto 0 auto 0' }}>
                        <FormControlLabel control={<Checkbox checked={autogenerate} onChange={(e) => {
                            if (e.target.checked) {
                                setautoGenerate(e.target.checked);
                                setUserData({ ...userData, password: randomNumber(6) });
                            } else {
                                setUserData({ ...userData, password: '' });
                            }
                        }} />} label="Auto Generate" />
                    </Grid>
                </Grid>
                <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                    <InputLabel htmlFor="User Phone">Phone</InputLabel>
                    <OutlinedInput
                        id="user-phone"
                        type='number'
                        value={phone}
                        onChange={(e) => setUserData({ ...userData, phone: e.target.value })}
                        startAdornment={
                            <InputAdornment position="start" disablePointerEvents>
                                <Phone />
                            </InputAdornment>
                        }
                        label="Email"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                    <InputLabel htmlFor="User Role">User Role</InputLabel>
                    <Select
                        id="user-role"
                        value={userType}
                        onChange={(e) => setUserData({ ...userData, userType: e.target.value })}
                        startAdornment={
                            <InputAdornment position="start" disablePointerEvents >
                                <Shield sx={{ color: userType === 0 ? "#ff6d00" : (userType === 1 ? "#ffab00" : "#3f51b5") }} />
                            </InputAdornment>
                        }
                        required

                        renderValue={(selected) => {
                            switch (userType) {
                                case 0:
                                    return (<Box sx={{ color: "#ff6d00 !important" }}>Admin</Box>);
                                case 1:
                                    return (<Box sx={{ color: "#ffab00 !important" }}>Manager</Box>);
                                case 2:
                                    return (<Box sx={{ color: "#3f51b5 !important" }}>User</Box>);
                                default:
                                    return "";
                            }
                        }}
                        label="User Role">
                        <MenuItem value={0} sx={{ margin: 1, color: "#ff6d00 !important;", backgroundColor: 'transparent !important' }}><AdminPanelSettings /> &nbsp; Admin</MenuItem>
                        <MenuItem value={1} sx={{ margin: 1, color: "#ffab00 !important", backgroundColor: 'transparent !important' }}><SupervisorAccount />&nbsp; Manager</MenuItem>
                        <MenuItem value={2} sx={{ margin: 1, color: '#3f51b5 !important', backgroundColor: 'transparent !important' }}><Person2 />&nbsp; User</MenuItem>
                    </Select>
                </FormControl>
                <Box textAlign={"end"}>
                    <Button
                        variant="contained"
                        sx={{ margin: "3px" }}
                        onClick={async () => {
                            console.log('Send Data to Server....', userData);
                            var response;
                            if (!email || !name || !phone || !storeID) {
                                console.log('Missing Data...!!');
                                return;
                            }
                            if (userData.userID) {
                                //this is time to edit existing user
                                response = await editUser(userData);

                            } else {
                                if (!password) {
                                    console.log('Missing Data');
                                    return;
                                }
                                response = await addUser(userData);
                                console.log(await response);

                            }
                            setlistStoreUser(await response.data?response.data:listStoreuser);
                            handleCloseModel();
                        }}
                    >Save</Button>
                </Box>
            </ModalUnstyled>
            {/* This Dialog for Confirmation */}
            <Dialog open={dialogInput.dialog} onClose={() => { setDialogInput({ dialog: false, userid: '' }) }}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this User?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogInput({ dialog: false, userid: '' }) }}>Cancel</Button>
                    <Button onClick={async () => {
                        console.log('Try to delete userID: ', dialogInput.userid);
                        var data = await deleteUser({
                            storeID: storeID,
                            userID: dialogInput.userid
                        });
                        setlistStoreUser(data.data);
                        //finally 
                        setDialogInput({ dialog: false, userid: '' });
                    }} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}