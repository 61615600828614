import React from "react";
import { ListItemButton, ListItemIcon, ListItemText, ListItem } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";

export const ListDrawerItem = ({ title, icon, mode, action, disable }) => {
    const navigate = useNavigate();
    const location = useLocation();


  return (
    <ListItem key={'dli-'+title} disablePadding sx={{ display: 'block' }}>
    <ListItemButton
      sx={{
        minHeight: 48,
        justifyContent: mode ? 'initial' : 'center',
        px: 2.5,
      }}
      onClick={()=>{
        navigate(action , { replace: true });
      }}
      selected={action === location.pathname}
      disabled={disable}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: mode ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
       {icon}
      </ListItemIcon>
      <ListItemText primary={title} sx={{ opacity: mode ? 1 : 0 }} />
    </ListItemButton>
  </ListItem>
  );
};
