import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Icon from '@mui/material/Icon';

const options = [
  { value: 'option1', label: 'Option 1', content: 'Content for Option 1', badgeContent: 3, icon: 'star' },
  { value: 'option2', label: 'Option 2', content: 'Content for Option 2', badgeContent: 7, icon: 'grade' },
  { value: 'option3', label: 'Option 3', content: 'Content for Option 3', badgeContent: 0, icon: 'favorite' }
];

const CustomSelectMenu = () => {
  return (
    <Select
      label="Select"
      defaultValue=""
      sx={{ width: 300 }}
      renderValue={(selected) => {
        const option = options.find(opt => opt.value === selected);
        return (
          <Card sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon sx={{ fontSize: 40, mr: 1 }}>{option ? option.icon : ''}</Icon>
            <CardContent>
              <Typography variant="body1">
                {option ? option.label : 'Select'}
              </Typography>
            </CardContent>
            {option && option.badgeContent > 0 && (
              <Badge badgeContent={option.badgeContent} color="error">
                {/* Badge Content */}
              </Badge>
            )}
          </Card>
        );
      }}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Card sx={{ display: 'flex', alignItems: 'center' }}>
            <Icon sx={{ fontSize: 40, mr: 1 }}>{option.icon}</Icon>
            <CardContent>
              <Typography variant="body1">{option.label}</Typography>
              <Typography variant="body2" color="text.secondary">
                {option.content}
              </Typography>
            </CardContent>
            {option.badgeContent > 0 && (
              <Badge badgeContent={option.badgeContent} color="error">
                {/* Badge Content */}
              </Badge>
            )}
          </Card>
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomSelectMenu;
