import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/Login";
import { HomePage } from "./pages/Home";
import { ProfilePage } from "./pages/Profile";
import { SettingsPage } from "./pages/Settings";
// import { ProtectedLayout } from "./components/ProtectedLayout";
import {MiniDrawer} from "./components/DrawerLayout";
import {UndefinePage} from "./pages/Undefine";
import { Morders } from "./pages/manager/Morders";
import { Mmenu } from "./pages/manager/Mmenu";
import { Msetting } from "./pages/manager/Msetting";
import { Mdashboard } from "./pages/manager/MDashboard";
import { ManagerDrawer } from "./pages/manager/DrawerLayout";
import { MenuPage } from "./pages/admin/MenuPage";
import { SignupPage } from "./pages/signup";
import { StoreSelect } from "./pages/StoreSelect";
import { Adash } from "./pages/admin/Adash";
import { CreateOrder } from "./pages/admin/createorder";
import  { Order } from "./pages/admin/order";
import { LogoutPage } from "./pages/logoutPage";
import LayoutBuilder from "./LayoutBuilder/layoutBuilder";

export default function App() {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<SignupPage />} />
      </Route>
      <Route path="/store" element={<StoreSelect />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/manager" element={<ManagerDrawer />}>
        <Route path="dashboard" element={<Mdashboard />} />
        <Route path="orders" element={<Morders />} />
        <Route path="menu" element={<Mmenu />} />
        <Route path="setting" element={<Msetting />} />
        
      </Route>
      <Route path="/admin" element={<MiniDrawer />}>
        <Route path="" element={<Adash />} />
        <Route path="order" element={<Order />} />
        <Route path="reservation" element={<UndefinePage />} />
        <Route path="menu" element={<MenuPage />} />
        <Route path="layout" element={<LayoutBuilder />} />
        <Route path="online" element={<UndefinePage />} />
        <Route path="reports" element={<UndefinePage />} />
        <Route path="settings" element={<SettingsPage />} />
        {/* <Route path="profile" element={<ProfilePage />} /> */}
        {/* following is temporary.. */}
        <Route path="create" element={<CreateOrder />} />
      </Route>
      <Route path="*" element={<UndefinePage />} />
    </Routes>
  )
}