import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import React from 'react';




export const StoreCard = ({ type, storeName, storeid, address, cardActionHandler }) => {
    return (
        <Grid item key={storeName}>
            <Card xs={2} elevation={6} >
                <CardActionArea onClick={()=>cardActionHandler(type,storeid)}
                >
                    <center>{type === 0 ? <Typography sx={{ backgroundColor: 'orange' }}>Admin</Typography> : <Typography sx={{ color: 'lime' }}>(Manager)</Typography>}</center>
                    <CardMedia
                        component="img"
                        height="100"
                        width="170"
                        image=""
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {storeName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {storeid} <br />
                            {address} <br />

                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    );
}