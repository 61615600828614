import React from "react";
import { Container } from "@mui/material";

export const Mdashboard = () => {
  return (
    <Container>
      <h6> Dashboard</h6>
    </Container>
  );
};
