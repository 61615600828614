import React, { useEffect, useRef, useState } from "react";
import OrderCardView from "../../components/orderCardView";
import OrderCollapsibleCard from "../../components/orderlistView";
import { Container } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { getCurrentOrder } from "../../hooks/apiHandler";

export const Order = () => {
    const { Logout } = useAuth();
    var storeID = useRef(JSON.parse(window.localStorage.getItem('ds')));
    const [orderData, setOrderData] = useState();
    const [reloadFlag, setReloadFlag] = useState(false);
    const cdata = {
        currentOrder: 150,
        inStore: 50,
        online: 75,
        partners: 25,
        completed: 300,
    };
    //const { data, loading } = ApiPostH('/currentOrder', { storeID: JSON.parse(window.localStorage.getItem('ds')) });

    useEffect(() => {
        async function callOrderData() {
            var response = await getCurrentOrder({ storeID: storeID.current }, Logout);
            setOrderData(await response.data);
        }
        callOrderData()
        setReloadFlag(false);
    }, [reloadFlag,Logout]);

    return (
        <Container>
            <h6> Order Dashboard</h6>
            <OrderCardView {...cdata} />
            {/* <OrderCollapsibleCard key={} title={} orders={}/> */}
            {(orderData) ? (orderData.map((item, pindex) => (
                <OrderCollapsibleCard key={`cca-${pindex}`} title={item.table} orders={item.orders} />
            ))) : (<p>Loading.....</p>)
                // loading ? () : (
                //     data.data.map((item, pindex) => (
                //         <OrderCollapsibleCard key={`cca-${pindex}`} title={item.table} orders={item.orders} />
                //     ))
                //     // console.log(data)
                // )
            }
        </Container >
    );
};
