import { DeleteForever, ExpandLess, ExpandMore, ImportantDevices, Microwave, Payments, Print, QrCodeScanner, Restaurant, Tv } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

import { Button, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Typography, colors } from "@mui/material";
import { generateDeviceToken, getdeviceInfo, removeDevice } from "../../hooks/apiHandler";
import TokenPopup from "./TokenPopup";
import { useAuth } from "../../hooks/useAuth";

export const DeviceSetting = () => {
    const { Logout } = useAuth();
    var storeID = useRef(JSON.parse(window.localStorage.getItem('ds')));
    const [reloadFlag, setReloadFlag] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState();
    const [dialogInput, setDialogInput] = useState({
        dialog: false,
        deviceid: ''
    });
    const [listAct, setListAct] = useState({
        screen: false,
        printer: false,
        cashDrawer: false,
        scanner: false,
        cscreen: false,
        kds: false,
    });

    useEffect(() => {
        console.log(storeID.current)
        async function calldeviceList() {
            var response = await getdeviceInfo({ storeID: storeID.current }, Logout);
            setDeviceInfo(await response.data);
        }
        calldeviceList()
        setReloadFlag(false);
        return () => {
            // this is same as component unmount of leaving the page..
        }
    }, [reloadFlag]);

    const [token, setToken] = useState({
        open: false,
        time: 0,
        token: ''
    });
    return (
        // <BasicPage title="Printer Settings" icon={<PrintSharp />} />
        <div>
            <List>
                <li key={'screens'}>
                    <ListItemButton onClick={() => setListAct(prev => ({ ...prev, screen: !listAct.screen }))}>
                        <ListItemIcon>
                            <ImportantDevices />
                        </ListItemIcon>
                        <ListItemText primary={`Screens (${(deviceInfo) ? deviceInfo.devices.length : '0'})`} autoCapitalize="true" />
                        <Button
                            onClick={
                                async () => {
                                    // console.log('This is called to generate Token..');
                                    var response = await generateDeviceToken({ storeID: storeID.current });
                                    // console.log(response);
                                    if (response.code === 0) {
                                        console.log('token Card');
                                        console.log(response.data);
                                        setToken({ token: response.data.token, open: true, time: response.data.time });
                                    }
                                }
                            }
                            variant="outlined" >+ Add Screen</Button>
                        &ensp;&ensp;&nbsp;
                        {listAct.screen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={listAct.screen} timeout="auto" unmountOnExit key={`collapse-`}>
                        <List disablePadding>
                            {(deviceInfo) ? (
                                deviceInfo.devices.map((item, index) => (
                                    <li key={'sc-' + index}>
                                        <ListItemButton sx={{ pl: 4 }} key={`nest-`} >
                                            <ListItemIcon>
                                                <Restaurant />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={
                                                <div>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.hid}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Device Modal : </strong> {item.os}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Token expire in : </strong> 20 days
                                                    </Typography>
                                                </div>
                                            } />
                                            <IconButton
                                                onClick={async () => {
                                                    setDialogInput({ dialog: true, deviceid: item._id });
                                                }}
                                            >
                                                <DeleteForever style={{ color: colors.orange[900] }} />
                                            </IconButton>
                                        </ListItemButton>
                                    </li>))) : ''}
                        </List>
                    </Collapse>
                </li>
                <li key={'printers'}>
                    <ListItemButton onClick={() => setListAct(prev => ({ ...prev, printer: !listAct.printer }))}>
                        <ListItemIcon>
                            <Print />
                        </ListItemIcon>
                        <ListItemText primary={`printers (${(deviceInfo) ? deviceInfo.printers.length : '0'})`} autoCapitalize="true" />
                        <Button
                            onClick={
                                async () => {
                                    // console.log('This is called to generate Token..');
                                    // var response = await generateDeviceToken({ storeID: storeID });
                                    // // console.log(response);
                                    // if (response.code === 0) {
                                    //     console.log('token Card');
                                    //     console.log(response.data);
                                    //     setToken({ token: response.data.token, open: true, time: response.data.time });
                                    // }
                                }
                            }
                            variant="outlined" >+ Add Printer</Button>
                        &ensp;&ensp;&nbsp;
                        {listAct.printer ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={listAct.printer} timeout="auto" unmountOnExit key={`collapse-`}>
                        <List disablePadding>
                            {(deviceInfo?.printers.length > 0) ? (
                                deviceInfo.printers.map((item, index) => (
                                    <li key={'pr-' + index}>
                                        <ListItemButton sx={{ pl: 4 }} key={`nest-`} >
                                            <ListItemIcon>
                                                <Restaurant />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={
                                                <div>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.hid}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Device Modal : </strong> {item.os}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Token expire in : </strong> 20 days
                                                    </Typography>
                                                </div>
                                            } />
                                            <IconButton
                                                onClick={() => {
                                                    alert('remove device ')
                                                }}
                                            >
                                                <DeleteForever style={{ color: colors.orange[900] }} />
                                            </IconButton>
                                        </ListItemButton>
                                    </li>))) : <li key={'pr-default'}>Add printer to the system</li>}
                        </List>
                    </Collapse>
                </li>
                <li key={'cashdrawer'}>
                    <ListItemButton onClick={() => setListAct(prev => ({ ...prev, cashDrawer: !listAct.cashDrawer }))}>
                        <ListItemIcon>
                            <Payments />
                        </ListItemIcon>
                        <ListItemText primary={`Cash Drawers (${(deviceInfo) ? deviceInfo.scanners.length : '0'})`} autoCapitalize="true" />
                        <Button
                            onClick={
                                async () => {
                                    // console.log('This is called to generate Token..');
                                }
                            }
                            variant="outlined" >+ Add Drawer</Button>
                        &ensp;&ensp;&nbsp;
                        {listAct.cashDrawer ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={listAct.cashDrawer} timeout="auto" unmountOnExit key={`collapse-`}>
                        <List disablePadding>
                            {(deviceInfo?.scanners.length > 0) ? (
                                deviceInfo.scanners.map((item, index) => (
                                    <li key={'cd-' + index}>
                                        <ListItemButton sx={{ pl: 4 }} key={`nest-`} >
                                            <ListItemIcon>
                                                <Restaurant />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={
                                                <div>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.hid}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Device Modal : </strong> {item.os}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Token expire in : </strong> 20 days
                                                    </Typography>
                                                </div>
                                            } />
                                            <IconButton
                                                onClick={() => {
                                                    alert('remove device ')
                                                }}
                                            >
                                                <DeleteForever style={{ color: colors.orange[900] }} />
                                            </IconButton>
                                        </ListItemButton>
                                    </li>))) : <li key={'cd-default'}><ListItemText>Need to check with the system working..</ListItemText></li>}
                        </List>
                    </Collapse>
                </li>
                <li key={'Scanner'}>
                    <ListItemButton onClick={() => setListAct(prev => ({ ...prev, scanner: !listAct.scanner }))}>
                        <ListItemIcon>
                            <QrCodeScanner />
                        </ListItemIcon>
                        <ListItemText primary={`Scanners (${(deviceInfo) ? deviceInfo.scanners.length : '0'})`} autoCapitalize="true" />
                        <Button
                            onClick={
                                async () => {
                                    // console.log('This is called to generate Token..');

                                }
                            }
                            variant="outlined" >+ Add Scanner</Button>
                        &ensp;&ensp;&nbsp;
                        {listAct.scanner ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={listAct.scanner} timeout="auto" unmountOnExit key={`collapse-`}>
                        <List disablePadding>
                            {(deviceInfo) ? (
                                deviceInfo?.scanners.map((item, index) => (
                                    <li key={'sca-' + index}>
                                        <ListItemButton sx={{ pl: 4 }} key={`nest-`} >
                                            <ListItemIcon>
                                                <Restaurant />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={
                                                <div>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.hid}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Device Modal : </strong> {item.os}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Token expire in : </strong> 20 days
                                                    </Typography>
                                                </div>
                                            } />
                                            <IconButton
                                                onClick={() => {
                                                    alert('remove device ')
                                                }}
                                            >
                                                <DeleteForever style={{ color: colors.orange[900] }} />
                                            </IconButton>
                                        </ListItemButton>
                                    </li>))) : <li key={'sca-default'}>No Scanner Found</li>}
                        </List>
                    </Collapse>
                </li>
                <li key={'CliScreen'}>
                    <ListItemButton onClick={() => setListAct(prev => ({ ...prev, cscreen: !listAct.cscreen }))}>
                        <ListItemIcon>
                            <Tv />
                        </ListItemIcon>
                        <ListItemText primary={`Client Screen (${(deviceInfo) ? deviceInfo.cls.length : '0'})`} autoCapitalize="true" />
                        <Button
                            onClick={
                                async () => {
                                    // console.log('This is called to generate Token..');

                                }
                            }
                            variant="outlined" >+ Add Screen</Button>
                        &ensp;&ensp;&nbsp;
                        {listAct.cscreen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={listAct.cscreen} timeout="auto" unmountOnExit key={`collapse-`}>
                        <List disablePadding>
                            {(deviceInfo) ? (
                                deviceInfo?.cls.map((item, index) => (
                                    <li key={'cls-' + index}>
                                        <ListItemButton sx={{ pl: 4 }} key={`nest-`} >
                                            <ListItemIcon>
                                                <Restaurant />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={
                                                <div>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.hid}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Device Modal : </strong> {item.os}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Token expire in : </strong> 20 days
                                                    </Typography>
                                                </div>
                                            } />
                                            <IconButton
                                                onClick={() => {
                                                    alert('remove device ')
                                                }}
                                            >
                                                <DeleteForever style={{ color: colors.orange[900] }} />
                                            </IconButton>
                                        </ListItemButton>
                                    </li>))) : <li key={'cls-default'}>No Scanner Found</li>}
                        </List>
                    </Collapse>
                </li>
                <li key={'kds'}>
                    <ListItemButton onClick={() => setListAct(prev => ({ ...prev, kds: !listAct.kds }))}>
                        <ListItemIcon>
                            <Microwave />
                        </ListItemIcon>
                        <ListItemText primary={`Kitchen Display (${(deviceInfo) ? deviceInfo.kds.length : '0'})`} autoCapitalize="true" />
                        <Button
                            onClick={
                                async () => {
                                    // console.log('This is called to generate Token..');

                                }
                            }
                            variant="outlined" >+ Add Scanner</Button>
                        &ensp;&ensp;&nbsp;
                        {listAct.kds ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={listAct.kds} timeout="auto" unmountOnExit key={`collapse-`}>
                        <List disablePadding>
                            {(deviceInfo) ? (
                                deviceInfo?.kds.map((item, index) => (
                                    <li key={'kds-' + index}>
                                        <ListItemButton sx={{ pl: 4 }} key={`nest-`} >
                                            <ListItemIcon>
                                                <Restaurant />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} secondary={
                                                <div>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {item.hid}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Device Modal : </strong> {item.os}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary">
                                                        <strong>Token expire in : </strong> 20 days
                                                    </Typography>
                                                </div>
                                            } />
                                            <IconButton
                                                onClick={() => {
                                                    alert('remove device ')
                                                }}
                                            >
                                                <DeleteForever style={{ color: colors.orange[900] }} />
                                            </IconButton>
                                        </ListItemButton>
                                    </li>))) : <li key={'kds-default'}>No kds Found</li>}
                        </List>
                    </Collapse>
                </li>
            </List>
            {token.open ? <TokenPopup
                token={token.token}
                countdownTime={token.time}
                onTokenExpire={() => {
                    setToken({}) // also call device list...
                    setReloadFlag(false);
                }}
            /> : ''}
            <Dialog open={dialogInput.dialog} onClose={() => { setDialogInput({ dialog: false, deviceid: '' }) }}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove device?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setDialogInput({ dialog: false, deviceid: '' }) }}>Cancel</Button>
                    <Button onClick={async () => {
                        console.log('Try to delete userID: ', dialogInput.deviceid);
                        var resp = await removeDevice({
                            storeID: storeID.current,
                            deviceType: 'devices',
                            id: dialogInput.deviceid
                        });
                        setDeviceInfo(resp.data);
                        //finally 
                        setDialogInput({ dialog: false, deviceid: '' });
                    }} variant="contained" color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
