import moment from 'moment';

export const renderTime = (date) => {
    let dateTime = moment(Date(date));
    let hour = dateTime.hour();
    let minutes = dateTime.minute();
    return hour + ":" + minutes;
};

export const randomNumber = (length) => {
    let otp = '';
    for (let i = 0; i < length; i++) {
        otp += Math.floor(Math.random() * 10).toString();
    }
    return otp;
}

export const url = 'https://api.miira.ca/v1';
// export const url = 'http://localhost:1717/v1';

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}