import React from "react";
import { BasicPage } from "../components/BasicPage";



export const UndefinePage = () => {
  return <BasicPage title="Welcome to \U404" icon={<card />} />;
};


