import React, { useCallback, useState } from "react";
import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { addStore, loginRest, signupRestaurant, ListStore } from "./apiHandler";
import { useSnackbar } from "./SnackbarProvider";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [listStore, setListStore] = useState([]);
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const Logout = useCallback(() => {
    // Remove user from localstorage..
    setUser(null);
    navigate("/login", { replace: true });
  },[setUser, navigate]);


  const login = useCallback(async (data) => {
    try {
      const resp = await loginRest(data, Logout);
      if (resp.code === 0) {
        setUser(resp.data);
        showSnackbar('Login successful!', 'success');
      } else {
        showSnackbar('Error: ' + resp.msg, 'error');
      }
    } catch (error) {
      console.log('Login error:', error);
      showSnackbar(error.msg, 'error');
    }
  }, [setUser,showSnackbar,Logout]);

  const signup = useCallback(async (data) => {
    try {
      const resp = await signupRestaurant(data);
      if (resp.code === 0) {
        showSnackbar('Account created successful!', 'success');
        return true;
      } else {
        showSnackbar('Error: ' + resp.msg, 'error');
      }
    } catch (error) {
      console.log('Login error:', error);
      showSnackbar(error.msg, 'error');
    }
  },[showSnackbar]);

  const updateStoreList = useCallback(async () =>{
    const resp = await ListStore(Logout);
    return setListStore(await resp.data);
  },[Logout]);

  const addcmpny = useCallback(async (data) => {
    try {
      const resp = await addStore(data, Logout);
      if (resp.code === 0) {
        showSnackbar('Account created successful!', 'success');
        return true;
      } else {
        showSnackbar('Error: ' + resp.msg, 'error');
      }
    } catch (error) {
      console.log('Login error:', error);
      showSnackbar(error.msg, 'error');
    }
  },[showSnackbar, Logout]);

  const value = useMemo(
    () => ({
      user,
      listStore,
      login,
      signup,
      Logout,
      addcmpny,
      updateStoreList,
    }),
    [user, listStore, login, signup, Logout, addcmpny, updateStoreList]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
