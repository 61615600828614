export let menu = [
    {
        menuName: "SOUP",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "MANCHOW (VEG/ CHICKEN)",
                price: "$11/ $13",
                decription: ""
            },
            {
                itemName: "HOT & SOUR (VEG/ CHICKEN)",
                price: "$11/ $13",
                decription: ""
            },
            {
                itemName: "THUKPA (VEG/ CHICKEN)",
                price: "$12/ $13",
                decription: ""
            },
            {
                itemName: "SWEET CORN (VEG/ CHICKEN)",
                price: "$10/ $12",
                decription: ""
            },
            {
                itemName: "TOMATO SOUP",
                price: "$11",
                decription: ""
            },
        ]
    },
    {
        menuName: "CHINESE",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "CHINESE HAKKA NOODLE",
                price: "$12/ $13/ $14/ $16",
                decription: "Veg schezwan/ Chicken/ Shrimp"
            },
            {
                itemName: "CHINESE MANCHURIAN",
                price: "$12/ $12/ $13",
                decription: "Veg/ Gobi/ Chicken"
            },
            {
                itemName: "CHINESE FRIED RICE",
                price: "$11/ $12/ $12/ $14",
                decription: "Veg/ Schezwan/ Mix egg/ Veg manchurian"
            },
            {
                itemName: "CHINESE FRIED RICE",
                price: "$13/ $16",
                decription: "Chicken/ Shrimp"
            },
        ]
    },
    {
        menuName: "VEG APPETIZERS",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "VEG PAKORA",
                price: "$9",
                decription: ""
            },
            {
                itemName: "MIX PAKORA PLATTER",
                price: "$11",
                decription: ""
            },
            {
                itemName: "PANEER PAKORA",
                price: "",
                decription: ""
            },
            {
                itemName: "GOL GAPPE",
                price: "",
                decription: ""
            },
            {
                itemName: "PAPDI CHAT",
                price: "",
                decription: ""
            },
            {
                itemName: "DAHI BHALLA",
                price: "",
                decription: ""
            },
            {
                itemName: "VEGETABLE SAMOSA WITH CHOLE",
                price: "",
                decription: ""
            },
            {
                itemName: "PUNJAABI PANEER TIKKA SIZZLER",
                price: "",
                decription: ""
            },
            {
                itemName: "ASIAN VEGETABLE SIZZLER",
                price: "",
                decription: ""
            },
            {
                itemName: "MEXICAN SIZZLER",
                price: "",
                decription: ""
            },
            {
                itemName: "SHANGHAI CHILLY PANEER",
                price: "",
                decription: ""
            },
            {
                itemName: "CRISPY SCHEZWAN POTATOES",
                price: "",
                decription: ""
            },
            {
                itemName: "VEG MOMOS",
                price: "",
                decription: ""
            },
            {
                itemName: "SCHEZWAN MOMOS",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "NON-VEG APPETIZERS",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "JALLANDHARI MURGH TANDOORI",
                price: "",
                decription: ""
            },
            {
                itemName: "PESHAWARI CHICKEN TIKKA",
                price: "",
                decription: ""
            },
            {
                itemName: "HARIYALI MURGH TIKKA",
                price: "",
                decription: ""
            },
            {
                itemName: "CHICKEN PAKORA",
                price: "",
                decription: ""
            },
            {
                itemName: "CHICKEN MOMOS",
                price: "",
                decription: ""
            },
            {
                itemName: "CHILLY CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "SEEKH KABAB (LAMB OR CHICKEN)",
                price: "",
                decription: ""
            },
            {
                itemName: "AMRITSARI FISH PAKORA",
                price: "",
                decription: ""
            },
            {
                itemName: "LAHORI FISH TIKKA",
                price: "",
                decription: ""
            },
            {
                itemName: "CHILLY GARLIC FISH",
                price: "",
                decription: ""
            },
            {
                itemName: "DYNAMITE SHRIMP",
                price: "",
                decription: ""
            },
            {
                itemName: "SALT & PEPPER SHRIMP",
                price: "",
                decription: ""
            },
            {
                itemName: "SAFFRON PRAWN TIKKA",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "VEG CURRY",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "DAAL MAKHANI",
                price: "",
                decription: ""
            },
            {
                itemName: "DAL TADKA",
                price: "",
                decription: ""
            },
            {
                itemName: "AMRITSARI PANEER",
                price: "",
                decription: ""
            },
            {
                itemName: "PANEER MAKHANWALA",
                price: "",
                decription: ""
            },
            {
                itemName: "SPECIAL PANEER PUNJAABI",
                price: "",
                decription: ""
            },
            {
                itemName: "MUTTER PANNER",
                price: "",
                decription: ""
            },
            {
                itemName: "SHAHI PANEER",
                price: "",
                decription: ""
            },
            {
                itemName: "PALAK PANEER",
                price: "",
                decription: ""
            },
            {
                itemName: "VEGETABLE JALFREZI",
                price: "",
                decription: ""
            },
            {
                itemName: "VEGETABLE KOLHAPURI",
                price: "",
                decription: ""
            },
            {
                itemName: "PANEER PASANDA",
                price: "",
                decription: ""
            },
            {
                itemName: "PANEER BHURJI",
                price: "",
                decription: ""
            },
            {
                itemName: "VEGETABLE NAVRATAN KORMA",
                price: "",
                decription: ""
            },
            {
                itemName: "VEGETABLE JAIPURI",
                price: "",
                decription: ""
            },
            {
                itemName: "BHINDI MASALA",
                price: "",
                decription: ""
            },
            {
                itemName: "METHI MALAI MUTTER",
                price: "",
                decription: ""
            },
            {
                itemName: "ALOO GHOBI",
                price: "",
                decription: ""
            },
            {
                itemName: "MALAI KOFTA",
                price: "",
                decription: ""
            },
            {
                itemName: "KAJU (CASHEW) CURRY",
                price: "",
                decription: ""
            },
            {
                itemName: "PUNJABI MASALEDAR CHOLE",
                price: "",
                decription: ""
            },
            {
                itemName: "BAINGAN BHARTA",
                price: "",
                decription: ""
            },
            {
                itemName: "CHEESE BUTTER MASALA",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "NON-VEG CURRY",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "BUTTER CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "CHICKEN TARIWALA",
                price: "",
                decription: ""
            },
            {
                itemName: "HANDI CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "CHENNAI CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "CHICKEN KEEMA",
                price: "",
                decription: ""
            },
            {
                itemName: "CHICKEN KORMA",
                price: "",
                decription: ""
            },
            {
                itemName: "MANGO CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "COCONUT CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "PUNJAABI TRADITIONAL CHICKEN",
                price: "",
                decription: ""
            },
            {
                itemName: "FISH GOAN CURRY",
                price: "",
                decription: ""
            },
            {
                itemName: "KADHAI",
                price: "",
                decription: "Chicken, Lamb, Goat"
            },
            {
                itemName: "CHICKEN TIKKA MASALA",
                price: "",
                decription: "Lamb, Prawn"
            },
            {
                itemName: "GOAN VINDALOO",
                price: "",
                decription: "Chicken, Lamb, Prawn"
            },
            {
                itemName: "SAAG",
                price: "",
                decription: "Chicken, Lamb, Prawn"
            },
            {
                itemName: "LAAL MAAS",
                price: "",
                decription: "Goat, Lamb"
            },
        ]
    },
    {
        menuName: "BREAD",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "TANDOORI ROTI",
                price: "",
                decription: ""
            },
            {
                itemName: "TAWA ROTI",
                price: "",
                decription: ""
            },
            {
                itemName: "PLAIN NAAN",
                price: "",
                decription: ""
            },
            {
                itemName: "BUTTER NAAN",
                price: "",
                decription: ""
            },
            {
                itemName: "GARLIC NAAN",
                price: "",
                decription: ""
            },
            {
                itemName: "PUNAABI LACHA PARATHA",
                price: "",
                decription: ""
            },
            {
                itemName: "ONION KULCHA",
                price: "",
                decription: ""
            },
            {
                itemName: "EXTRA BUN",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "RICE & BIRYANI",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "PUNJAABI SPECIAL VEG PULAO",
                price: "",
                decription: ""
            },
            {
                itemName: "VEG BIRYANI",
                price: "",
                decription: ""
            },
            {
                itemName: "EGG BIRYANI",
                price: "",
                decription: ""
            },
            {
                itemName: "CHICKEN BIRYANI",
                price: "",
                decription: ""
            },
            {
                itemName: "LAMB BIRYANI/ GOAT BIRYANI",
                price: "",
                decription: ""
            },
            {
                itemName: "SHRIMP BIRYANI",
                price: "",
                decription: ""
            },
            {
                itemName: "STEAM RICE",
                price: "",
                decription: ""
            },
            {
                itemName: "JEERA RICE",
                price: "",
                decription: ""
            },
            {
                itemName: "COCONUT RICE",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "EGG ITEMS",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "OMELETTE",
                price: "",
                decription: ""
            },
            {
                itemName: "SURATI EGG GOTALO",
                price: "",
                decription: ""
            },
            {
                itemName: "EGG TIKKA MASALA",
                price: "",
                decription: ""
            },
            {
                itemName: "MASALA FRY EGG",
                price: "",
                decription: ""
            },
            {
                itemName: "EGG CURRY",
                price: "",
                decription: ""
            },
            {
                itemName: "AANDA BHURJI",
                price: "",
                decription: ""
            },
            {
                itemName: "EGG RAVAIYA",
                price: "",
                decription: ""
            },
            {
                itemName: "EGG PAPLET",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "SIDE DISHES",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "GREEN SALAD",
                price: "",
                decription: ""
            },
            {
                itemName: "ONION SALAD WITH FRY CHILLI",
                price: "",
                decription: ""
            },
            {
                itemName: "BOONDI RAITA",
                price: "",
                decription: ""
            },
            {
                itemName: "PUNJAABI VEGETABLE RAITA",
                price: "",
                decription: ""
            },
            {
                itemName: "YOGURT PLAIN",
                price: "",
                decription: ""
            },
            {
                itemName: "PAPADUM (2pcs)",
                price: "",
                decription: ""
            },
            {
                itemName: "MASALA PAPADUM",
                price: "",
                decription: ""
            },
        ]
    },
    {
        menuName: "BEVERAGES",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "SOFT DRINK",
                price: "",
                decription: "Coke, Diet Coke, Sprite, Ginger ale, Pepsi"
            },
            {
                itemName: "MASALA TEA [Chai]",
                price: "",
                decription: ""
            },
            {
                itemName: "JUICE",
                price: "",
                decription: "Apple, Cranberry, Orange, Mango, Lychee"
            },
            {
                itemName: "REAL LEMON SODA",
                price: "",
                decription: ""
            },
            {
                itemName: "LASSI",
                price: "",
                decription: "Mango, Salted, Sweet"
            },
            {
                itemName: "ROYAL PUNJAABI LASSI",
                price: "",
                decription: ""
            },
            {
                itemName: "WATERMELON MOJITO",
                price: "",
                decription: "with mint, lemon, soda and ice"
            },
        ]
    },
    {
        menuName: "DESSERTS",
        imgName: "matterPanner.png",
        items: [
            {
                itemName: "GAJRELA",
                price: "",
                decription: ""
            },
            {
                itemName: "ICE CREAM",
                price: "",
                decription: ""
            },
            {
                itemName: "RASMALAI [2pcs]",
                price: "",
                decription: ""
            },
            {
                itemName: "GULAB JAMUN [3pcs]",
                price: "",
                decription: ""
            },
            {
                itemName: "MOONG DAL HALWA",
                price: "",
                decription: ""
            },
            {
                itemName: "WHITE CHOCOLATE BROWNIE",
                price: "",
                decription: ""
            },
        ]
    },


]