import React from "react"
export const LogoutPage = () => {

    return (
        <div>
            <center>
                <h1>Welcome to Logout</h1>
            </center>
        </div>
    );
}