import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Box, Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GeneralSetting } from "./settings/general";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { AddToQueue, CurrencyExchangeOutlined, Key, ManageAccounts, Notifications, Settings } from "@mui/icons-material";
import { DeviceSetting } from "./settings/Devices";
import { UserSetting } from "./settings/user";
import { PaymentSetting } from "./settings/payment";
import { NotificationSetting } from "./settings/notification";

export const SettingsPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [tabno, setTabno] = useState("1");

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true })
    }

  }, [user,navigate])


  const tabChange = (_event, newValue) => {
    setTabno(newValue);
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabno}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={tabChange} aria-label="Settings">
            <Tab value="1" icon={<Settings />} iconPosition="start" label="General" />
            <Tab value="2" icon={<AddToQueue />} iconPosition="start" label="Devices" />
            <Tab value="3" icon={<ManageAccounts />} iconPosition="start" label="user" />
            <Tab value="4" icon={<CurrencyExchangeOutlined />} iconPosition="start" label="Payments" />
            <Tab value="5" icon={<Notifications />} iconPosition="start" label="Notifications" />
          </TabList>
        </Box>
        <TabPanel value="1"><GeneralSetting /></TabPanel>
        <TabPanel value="2"><DeviceSetting /></TabPanel>
        <TabPanel key={Key} value="3"><UserSetting /></TabPanel>
        <TabPanel value="4"><PaymentSetting /></TabPanel>
        <TabPanel value="5"><NotificationSetting /></TabPanel>
      </TabContext>
    </Box>
  )
};
