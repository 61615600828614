import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  Box
} from '@mui/material';
import { Shield, AdminPanelSettings, SupervisorAccount, Person2 } from '@mui/icons-material';

const CtSelect = () => {
  const [usertype, setUserType] = useState('');

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };

  return (
    <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
      <InputLabel htmlFor="user-role">User Role</InputLabel>
      <Select
        id="user-role"
        value={usertype}
        onChange={handleUserTypeChange}
        startAdornment={
          <InputAdornment position="start" disablePointerEvents>
            <IconButton aria-label="User Password" edge="start">
              <Shield />
            </IconButton>
          </InputAdornment>
        }
        required
        label="User Role"
      >
        <MenuItem value={0}><AdminPanelSettings />&nbsp; Admin</MenuItem>
        <MenuItem value={1}><SupervisorAccount />&nbsp; Manager</MenuItem>
        <MenuItem value={2}><Person2 />&nbsp; User</MenuItem>
      </Select>
      {usertype === 0 && (
        <Box sx={{ color: "#ff6d00", marginLeft: '15px' }}>
          Admin Icon or Color
        </Box>
      )}
      {usertype === 1 && (
        <Box sx={{ color: "#ffab00", marginLeft: '15px' }}>
          Manager Icon or Color
        </Box>
      )}
      {usertype === 2 && (
        <Box sx={{ color: "#3f51b5", marginLeft: '15px' }}>
          User Icon or Color
        </Box>
      )}
    </FormControl>
  );
};

export default CtSelect;
