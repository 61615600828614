import React from "react";

import { Container } from "@mui/material";

export const Msetting = () => {
  return (
    <Container>
      <h2>Manager Settings</h2>
    </Container>
  );
};
