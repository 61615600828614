import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';

const ElementList = ({ elements, onDelete, cheigth, cwidth }) => {
    return (
        <div className="element-list">
            <h2>Element List</h2> Floor Height- {cheigth} Width- {cwidth}
            <ul>
                {elements.map((element) => (
                    <li key={element.gid}>
                        {element.gid}
                        <IconButton onClick={() => { onDelete(element.gid) }} aria-label="delete Element">
                            <DeleteIcon />
                        </IconButton> {element.id} {element.name} - X: {element.x}, Y: {element.y} , Size --  Height-{element.height}, Width-{element.width}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ElementList;
