import React, { useEffect, useState } from "react";

import { Box, Button, FormControl, Grid, Switch, TextField, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { editStoreInfo, getStoreInfo } from "../../hooks/apiHandler";
import { useSnackbar } from "../../hooks/SnackbarProvider";

export const GeneralSetting = () => {
    const theme = useTheme();
    const isMdOrLg = useMediaQuery(theme.breakpoints.up('md'));
    const [editMode, setEditMode] = useState(false);
    const [storeInfo, setStoreInfo] = useState({
        name: '',
        email: '',
        phone: 0,
        address: '',
        city: '',
        state: '',
        country: '',
        postCode: '',
        storeTimes: {}
    });
    const storeid = JSON.parse(window.localStorage.getItem('ds'));
    const showSnackbar = useSnackbar();


    const handleSwitchChange = (event, day) => {
        setStoreInfo((prevStoreInfo) => ({
          ...prevStoreInfo,
          storeTimes: {
            ...prevStoreInfo.storeTimes,
            [day]: {
              ...prevStoreInfo.storeTimes[day],
              storeOpen: event.target.checked,
            },
          },
        }));
      };
      
      const handleTimeChange = (event, day, timeType) => {
        const [hours, minutes] = event.target.value.split(':').map(Number);
        setStoreInfo((prevStoreInfo) => ({
          ...prevStoreInfo,
          storeTimes: {
            ...prevStoreInfo.storeTimes,
            [day]: {
              ...prevStoreInfo.storeTimes[day],
              [timeType]: {
                ...prevStoreInfo.storeTimes[day][timeType],
                hours,
                minutes,
              },
            },
          },
        }));
      };
      
      const timeDisplay = (time) => {
        const { hours, minutes } = time;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      };
      


    useEffect(() => {
        // Loading started
        async function fetchStoreData() {
            var response = await getStoreInfo({ storeID: storeid });
            if (await response) {
                setStoreInfo(response.data);
                // set loading false...
            }
        }
        fetchStoreData();
        // console.log(storeInfo);
        return () => {
            //this work like finally..??
        }
    }, [storeid]);

    return (
        <Box
            sx={{
                width: isMdOrLg ? '70%' : '100%', // Apply 70% width for md and lg, otherwise 100%
            }}
        >
            {/* <BasicPage title="General Settings" icon={<Settings />} /> */}
            <Grid container
                justifyContent="space-between"
                alignItems="center" >
                <Grid item xs={12} md={6}>
                    <h4> Store Info:</h4 >
                </Grid>
                <Grid item xs={12} md="auto">
                    {(editMode) ? (<Button
                        onClick={async () => {
                            setEditMode(false);
                            var response = await getStoreInfo({ storeID: storeid });
                            if (await response) {
                                setStoreInfo(response.data);
                                // set loading false...
                            }
                        }} >Cancel</Button>) : ''}

                    &nbsp;
                    {(!editMode) ?
                        (<Button
                            variant="outlined"
                            onClick={() => {
                                setEditMode(true);
                            }}

                        >Edit</Button>) : ''}
                    &nbsp;

                    {(editMode) ? (<Button variant="contained"
                        onClick={async () => {
                            // set loading to true...
                            console.log('time to update Store information...');
                            console.log(storeInfo);
                            try {
                                var resp = await editStoreInfo({ storeID: storeid, updates: storeInfo });
                                if (resp.code === 0) {
                                    setStoreInfo(resp.data);
                                    showSnackbar('Store Information updated successful!', 'success');
                                } else {
                                    showSnackbar('Error: ' + resp.msg, 'error');
                                }
                            } catch (error) {
                                console.log('Login error:', error);
                                showSnackbar(error.msg, 'error');
                            }
                            setEditMode(false);
                        }}
                    >Save</Button>) : ''}

                </Grid>
            </Grid>
            <hr />
            <Grid container alignItems="center" columns={4}>
                <Grid item xs={12} md={1} >
                    <Typography>Store Name : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="storename"
                            value={storeInfo.name}
                            type='text'
                            placeholder=""
                            required
                            size="small"
                            onChange={(e) => setStoreInfo({ ...storeInfo, name: e.target.value })}
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Typography>Store Phone : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="storeNumber"
                            value={storeInfo.phone}
                            type='number'
                            placeholder="(000)-000-0000"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Typography>Store Email : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="storeemail"
                            value={storeInfo.email}
                            type='text'
                            placeholder="xyz@store.com"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <h4>Store Address :</h4>
            <hr />
            <Grid container alignItems="center" columns={4}>
                <Grid item xs={12} md={1} >
                    <Typography>Address : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="address"
                            value={storeInfo.address}
                            type='text'
                            placeholder="123 Center Street NE"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Typography>City  : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="city"
                            value={storeInfo.city}
                            type='text'
                            placeholder="Calgary"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Typography>Postal Code  : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="postal-code"
                            type='text'
                            value={storeInfo.postCode}
                            placeholder="1A1 1B1"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Typography>State / province : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="state"
                            type='text'
                            value={storeInfo.state}
                            placeholder="Alberta"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={1} >
                    <Typography>Country : &nbsp;</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormControl sx={{ m: 1, width: { xs: '100%', md: '60ch' } }} variant="filled">
                        <TextField
                            variant="filled"
                            id="cntry"
                            type='text'
                            value={storeInfo.country}
                            placeholder="Canada"
                            required
                            size="small"
                            disabled={!editMode}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <h4>Store Timing :</h4>
            <hr />
            {/* <StoreTimes storeTimes={storeInfo.storeTimes} /> */}
            <Grid container alignItems="center" columns={4}>
                {Object.entries(storeInfo.storeTimes).map(([day, times]) => (
                    <React.Fragment key={day}>
                        <Grid item xs={6} md={1}>
                            <Typography sx={{ textTransform: 'capitalize' }}>{day}</Typography>
                        </Grid>

                        <Grid item xs={6} md={1}>
                            <Switch
                                checked={times.storeOpen}
                                onChange={(e) => handleSwitchChange(e, day)}
                                disabled={!editMode}
                            />
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <FormControl sx={{ m: 1, width: { xs: '100%', md: '17ch' } }} variant="filled">
                                <TextField
                                    variant="filled"
                                    id={`${day}-openTime`}
                                    type="time"
                                    value={timeDisplay(times.openTime)}
                                    onChange={(e) => handleTimeChange(e, day, 'openTime')}
                                    placeholder="09:00 AM"
                                    required
                                    size="small"
                                    disabled={!editMode}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <FormControl sx={{ m: 1, width: { xs: '100%', md: '17ch' } }} variant="filled">
                                <TextField
                                    variant="filled"
                                    id={`${day}-closeTime`}
                                    type="time"
                                    value={timeDisplay(times.closeTime)}
                                    onChange={(e) => handleTimeChange(e, day, 'closeTime')}
                                    placeholder="09:00 PM"
                                    required
                                    size="small"
                                    disabled={!editMode}
                                />
                            </FormControl>
                        </Grid>
                    </React.Fragment>
                ))}
            </Grid>

        </Box >
    )
}