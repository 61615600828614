import React, { useState, useEffect, useRef } from 'react';
import Element from './Element';
import ElementList from './ElementList';
import './index.css';
import { getLayout, saveLayout } from '../hooks/apiHandler';

const LayoutBuilder = () => {
  const storeID = JSON.parse(window.localStorage.getItem('ds'));
  const [elements, setElements] = useState([]);
  const [etype, setEType] = useState({
    table: 1, counter: 1
  })
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState({
    height: null,
    width: null
  });
  const [tableSize] = useState({
    tlength: 70,
    twidth: 70
  });
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getLayout({ storeID: storeID });
        return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        return null; // Return null or handle the error appropriately
      }
    }

    async function fetchDataAndSetState() {
      const savedElements = await fetchData();
      if (savedElements) {
        console.log('time to load Data');
        setCanvas({
          height: savedElements.canvasHeight,
          width: savedElements.canvasWidth
        });
        setElements(savedElements.layoutElement);
      }
    }

    fetchDataAndSetState();
    // if (canvasRef.current) {
    //   const canvas = canvasRef.current;
    //   const canvasWidth = canvas.offsetWidth;
    //   const canvasHeight = canvas.offsetHeight;
    //   setCanvas({ height: canvasHeight, width: canvasWidth });
    // }
  }, [storeID]);



  const handleAddElement = (type) => {
    if (!canvas.height || !canvas.width) {
      console.error('Canvas size not available');
      return;
    }
    var tid = 0;
    if (type === 'table') {
      tid = etype.table;
      setEType(preState => ({ ...preState, table: etype.table + 1 }));
    } else if (type === 'counter') {
      tid = etype.counter;
      setEType(preState => ({ ...preState, counter: etype.counter + 1 }));
    }
    console.log(etype);
    const newX = Math.random() * (canvas.width - 100);
    const newY = Math.random() * (canvas.height - 100);

    const newElement = {
      gid: elements.length + 1,
      id: tid,
      type,
      name: type,
      x: newX,
      y: newY,
      width: tableSize.twidth,
      height: tableSize.tlength,
      lbr: false
    };
    setElements([...elements, newElement]);
  };

  const handleSave = async () => {
    var allElements = rearrangeIDs(elements);
    const data = {
      canvasHeight: canvas.height,
      canvasWidth: canvas.width,
      allElements
    };
    //localStorage.setItem('layoutElements', JSON.stringify(data));
    console.log(data);

    await saveLayout({
      storeID: storeID,
      layout: data
    }).then((res) => {
      console.log('Server Reply:');
      console.log(res);
    });
  };

  const rearrangeIDs = (data) => {
    let tableCounter = 0;
    let counterCounter = 0;
    let commonGid = 0;

    const updatedData = [];
    data.forEach((item) => {
      if (item.type === 'table') {
        item.id = ++tableCounter;
        item.gid = ++commonGid;
      } else if (item.type === 'counter') {
        item.id = ++counterCounter;
        item.gid = ++commonGid;
      }
      updatedData.push(item);
    });

    return updatedData;
  };

  const handleDeleteElement = (gid) => {
    //setElements(elements.filter(element => element.gid !== gid));
    // Filter out the element with the given ID
    const filteredElements = elements.filter(element => element.gid !== gid);

    setElements(rearrangeIDs(filteredElements));
  };

  const setElement = (gid, elementDetails) => {
    setElements(elements.map(element =>
      element.gid === gid ? { ...element, name: elementDetails.name, height: elementDetails.height, width: elementDetails.width, lbr: elementDetails.lbr } : element
    ));
  };

  const handleElementPositionChange = (gid, newPosition) => {
    setElements(elements.map(element =>
      element.gid === gid ? { ...element, x: (Math.round(newPosition.x / 5) * 5), y: (Math.round(newPosition.y / 5) * 5) } : element
    ));
  };

  return (
    <div>
      <div className="controls">
        <button className="restaurant-table-button" onClick={() => handleAddElement('table')}>
          Add Table
        </button>
        &nbsp;
        <button className="restaurant-table-button" style={{ backgroundColor: 'grey', color: 'white' }} onClick={() => handleAddElement('counter')}>Add Counter</button>
        &nbsp;
        <button onClick={handleSave}>Save</button>
      </div>
      <div className="layout-builder">
        <div className="canvas-container" style={{ width: '70vw', height: '70vh' }}>
          <div className="canvas" ref={canvasRef}>
            {elements.map((element) => (
              <Element
                key={element.gid}
                {...element}
                canvasHeight={canvas.height}
                canvasWidth={canvas.width}
                onPositionChange={(newPosition) => handleElementPositionChange(element.gid, newPosition)}
                onElementChange={(elementDetail) => setElement(element.gid, elementDetail)}
              />
            ))}
          </div>
        </div>
      </div>
      <ElementList
        elements={elements}
        onDelete={handleDeleteElement}
        cheigth={canvas.height}
        cwidth={canvas.width}
      />
    </div>
  );
};

export default LayoutBuilder;
