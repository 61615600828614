import React from "react";
import { BasicPage } from "../components/BasicPage";
import Home from "@mui/icons-material/Home";
import CustomSelectMenu from "../temp/customeSelect";
import CtSelect from "../temp/ctselect";

export const HomePage = () => {
  return (
    <div>
      <BasicPage title="Home Page" icon={<Home />} />
      <center>
        <a href="/store">Dashboard &gt;</a>
      </center>
      <CustomSelectMenu />
      <CtSelect />
    </div>
  );
};
