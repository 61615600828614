import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material"
// import { TableContainer, Paper, TableBody, Table, TableHead, TableRow } from "@mui/material"
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Restaurant, MenuBook, Edit } from "@mui/icons-material";
import { Container } from "@mui/material";
import { menu } from "../data";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Collapse from '@mui/material/Collapse';
import { ModalUnstyled } from "../../modal/Modal";
import { AddMenuForm } from "../../Forms/AddMenuform";
import ApiPostH from "../../hooks/apiPostH";
import { getlistMenu } from "../../hooks/apiHandler";
// import { styled } from '@mui/material/styles';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   // hide last border
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

// const label = { inputProps: { 'aria-label': 'Size switch demo' } };

export const MenuPage = () => {

  var [listMenu, setMenuList] = useState([]);
  var storeID = useRef(JSON.parse(window.localStorage.getItem('ds')));

  const [showModal, setshowModal] = useState(false);


  function handleDropDown(index) {
    const updateField = [...listMenu];
    updateField[index].listAct = !(updateField[index].listAct);
    setMenuList(updateField);
  }

  useEffect(() => {
    async function calldeviceList() {
        var response = await getlistMenu({ storeID: storeID.current});
        setMenuList(await response.data);
    }
    calldeviceList()
    return() =>{
        // this is same as component unmount of leaving the page..
    }         
}, []);

  return (
    <Container>
      {/* This Following Should be in Admin Panel */}

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button variant="outlined" onClick={() => {
          setshowModal((prev) => !prev);
        }}>+Add Menu</Button>
      </Box>
      <Box sx={{ p: 2 }}></Box>
      {/* End of extra Admin Panel */}

      <List>
        {
          listMenu.length > 0 ? (
            listMenu.map((item, pindex) => (
              <li key={`lis-${pindex}`}>
                <ListItemButton onClick={() => handleDropDown(pindex)}>
                  <ListItemIcon>
                    <MenuBook />
                  </ListItemIcon>
                  <ListItemText primary={item.catgory} autoCapitalize="true" />
                  {listMenu[pindex].listAct ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={listMenu[pindex].listAct} timeout="auto" unmountOnExit key={`collapse-${pindex}`}>
                  <List disablePadding>
                    {item.items.map((nestedItem, index) => (
                      <li key={`neslis-${index}`} >
                        <ListItemButton sx={{ pl: 4 }} key={`nest-${index}`} >
                          <ListItemIcon>
                            <Restaurant />
                          </ListItemIcon>
                          <ListItemText primary={nestedItem.item} secondary={nestedItem.price} />
                          <Edit fontSize="medium" style={{color:'#b00020'}} onClick={() => {
                            console.log(pindex, index)
                          }} />
                        </ListItemButton>

                      </li>
                    ))}
                  </List>
                </Collapse>
              </li>
            ))):
            (<p>Loading.....</p>)
        }

        {/* {listData.map((item, pindex) => (
          <li key={`lis-${pindex}`}>
            <ListItemButton onClick={() => handleDropDown(pindex)}>
              <ListItemIcon>
                <MenuBook />
              </ListItemIcon>
              <ListItemText primary={item.menuName} autoCapitalize="true" />
              {listData[pindex].listAct ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={listData[pindex].listAct} timeout="auto" unmountOnExit key={`collapse-${pindex}`}>
              <List disablePadding>
                {item.items.map((nestedItem, index) => (
                  <ListItemButton sx={{ pl: 4 }} key={`nest-${index}`}>
                    <ListItemIcon>
                      <Restaurant />
                    </ListItemIcon>
                    <ListItemText primary={nestedItem.itemName} secondary={nestedItem.price} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </li>
        ))} */}
      </List>


      <ModalUnstyled
        shouldShow={showModal}
        onRequestClose={() => {
          setshowModal((prev) => !prev);
        }}
      >
        <h2>Add Menu Item</h2>
        <hr />
        <AddMenuForm />
      </ModalUnstyled>


      {/* this is in case of different view... */}
      {/* <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ID</StyledTableCell>
            <StyledTableCell align="right">Item</StyledTableCell>
            <StyledTableCell align="right">Catagory</StyledTableCell>
            <StyledTableCell align="right">Price</StyledTableCell>
            <StyledTableCell align="right">Available</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.calories}</StyledTableCell>
              <StyledTableCell align="right">{row.fat}</StyledTableCell>
              <StyledTableCell align="right">{row.carbs}</StyledTableCell>
              <StyledTableCell align="right">{row.protein}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}
    </Container>
  );
};
