import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";



export const SignupPage = () => {
    const { signup } = useAuth();
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(data.get("password") !== data.get("cpassword")){
            alert('Passwod not Match')
        }else{
            var resp = await signup({
                uname: data.get('name'),
                email: data.get("email"),
                phone: data.get("phone"),
                password: data.get("password"),
            });
            if(resp){
                navigate('/login', { replace: true });
            }
        }
        
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Box sx={{ mt: 1 }}>
                    {/* this Following Box is to handle Form submit */}
                    <Box component="form" onSubmit={handleSubmit} noValidate={false}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="phone"
                        label="Phone Number"
                        type="number"
                        id="phone"
                        autoComplete="tel"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="cpassword"
                        label="Confirm Password"
                        type="password"
                        id="cpassword"
                        autoComplete="new-password"
                    />
                    {/* <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="companyName"
                        label="Company Name"
                        type="text"
                        id="cmpname"
                        autoComplete="organization"
                    />
                     */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                         Sign Up
                    </Button>
                    </Box>
                    <Grid container>
                        <Grid item>
                                <Link href="/login" variant="body2">
                                    {"Already have an account? Login"}
                                </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}